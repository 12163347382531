<template>
  <transition name="fade">
    <signin v-if="isLoginOpen" @close="onCloseSignin" @signup="isLoginOpen = false;isSignupOpen = true"/>
  </transition>

  <transition name="fade">
    <TMmodal v-show="TMOpen" :isOpen="TMOpen" :type="'header'" @close="onCloseTM"/>
  </transition>

  <transition name="fade">
    <join v-if="isSignupOpen" @close="onCloseSignup"/>
  </transition>

  <transition name="fade">
    <join-complete v-if="isSignupCompleteOpen" @close="isSignupCompleteOpen = false;isLoginOpen = true"/>
  </transition>

  <transition name="fade">
    <new-message v-if="isUnReadMessage" />
  </transition>
  <!--transition name="slide">
     <div class="leftHeader" :class="{ 'out': nside }" v-if="nside">
        <ul>
         <li class="logowrap">
            <i @click="nside=!nside"><img src="@/assets/img/icon_menu.svg" /></i>
            <a @click="goPageByName('main')">
             <img src="@/assets/img/play_pokari.svg" />
            </a>
         </li>
         <li>
            <div class="leftNav">
              <ul>
                <--li @click="handleMenuClick('livecasinoSec')"><em></em>라이브카지노</li>
                <li @click="handleMenuClick('hotelcasinoSec')"><em></em>호텔카지노</li>
                <li @click="handleMenuClick('slotSec')"><em></em>슬롯</li->
                <li class="dropdown" @click="isGameOpen = !isGameOpen" v-if="gameCount['casino']" ><-- @click="gnbMenuOpen('game')" ->
                 <a class="dropdown-toggle" :class="{ 'on': isGameOpen }"><em class="lcasino"></em>{{ $t('front.gnb.casino') }}<i></i></a>
                   <transition>
                     <div class="dropdown-menu" v-if="isGameOpen">
                       <template v-if="gameCount['casino']">
                         <--ul class="navitop">
                           <li>{{ $t('front.gnb.livecasino') }}</li>
                         </ul->
                         <ul class="navibottom">
                           <li>
                             <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.code" >
                               <a @click="onCasinoSelectGame(item.groupCode, item.code)" v-if="item.groupCodeNameEN.indexOf('HC-') == -1">
                                 <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                                 <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
                               </a>
                             </template>
                           </li>
                         </ul>
                       </template>
                     </div>
                   </transition>
               </li>
              </ul>
              <ul>
               <li class="dropdown" @click="isHgameOpen = !isHgameOpen" v-if="gameCount['hc-casino']" ><-- @click="gnbMenuOpen('hgame')" ->
                <a class="dropdown-toggle" :class="{ 'on': isHgameOpen }"><em class="lhcasino"></em>{{ $t('front.gnb.hotelcasino') }}<i></i></a>
                  <transition>
                    <div class="dropdown-menu" v-if="isHgameOpen">
                      <template v-if="gameCount['hc-casino']">
                        <--ul class="navitop pt15">
                          <li>{{ $t('front.gnb.hotelcasino') }}</li>
                        </ul->
                        <ul class="navibottom">
                          <li>
                           <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.code" >
                              <a @click="onCasinoSelectGame(item.groupCode, item.code)" v-if="item.groupCodeNameEN.indexOf('HC-') > -1">
                                <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                                <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
                              </a>
                           </template>
                          </li>
                        </ul>
                      </template>
                    </div>
                  </transition>
                </li>
              </ul>
              <ul>
                 <li class="dropdown" @click="isSlotOpen = !isSlotOpen" v-if="gameCount['slot'] || gameCount['h-slot']" ><-- @click="gnbMenuOpen('slot')" ->
                   <a class="dropdown-toggle" :class="{ 'on': isSlotOpen }"><em class="lslot"></em>{{ $t('front.gnb.slot') }}<i></i></a>
                   <transition>
                     <div class="dropdown-menu" v-if="isSlotOpen">
                       <template v-if="gameCount['slot']">
                         <--ul class="navitop">
                           <li>{{ $t('front.gnb.slotgame') }}</li>
                         </ul->
                         <ul class="navibottom">
                           <li>
                             <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.codeName">
                               <a @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"  v-if="item.groupCodeNameEN.indexOf('H-') == -1">
                                 <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                                 <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
                               </a>
                             </template>
                           </li>
                         </ul>
                       </template>

                       <template v-if="gameCount['h-slot']">
                         <ul class="navitop">
                           <li>{{ $t('front.gnb.hotelslot') }}</li>
                         </ul>
                         <ul class="navibottom">
                           <li>
                             <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.codeName">
                               <a @click="slotOpen('isSlot', 'slot', item.codeName, item.code)" v-if="item.groupCodeNameEN.indexOf('H-') > -1">
                                 <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                                 <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
                               </a>
                             </template>
                           </li>
                         </ul>
                       </template>
                     </div>
                   </transition>
                 </li>
              </ul>
              <ul>
                <li @click="goPageByName('charge')" :class="{'on': $route.name === 'charge'}"><a><em class="lin"></em>입금</a></li>
                <li @click="goPageByName('exchange')" :class="{'on': $route.name === 'exchange'}"><a><em class="lout"></em>출금</a></li>
              </ul>
              <ul>
                <li @click="goPageByName('notice')" :class="{'on': $route.name === 'noticeList'}"><a><em class="lnotice"></em>공지사항</a></li>
                <li @click="goPageByName('csCenter')" :class="{'on': $route.name === 'qnaList'}"><a><em class="lcs"></em>고객센터</a></li>
                <li @click="goPageByName('faq')" :class="{'on': $route.name === 'faqList'}"><a><em class="lfaq"></em>자주하는질문</a></li>
              </ul>
              <ul>
                <li @click="goPageByName('bettingList')" :class="{'on': $route.name === 'bettingList'}"><a><em class="lbet"></em>베팅내역</a></li>
                <li @click="goPageByName('point')" :class="{'on': $route.name === 'pointUse'}"><a><em class="lpoint"></em>포인트</a></li>
                <li @click="goPageByName('message')" :class="{'on': $route.name === 'messageList'}"><a><em class="lmsg"></em>쪽지관리</a></li>
                <li @click="goPageByName('info')" :class="{'on': $route.name === 'info'}"><a><em class="linfo"></em>회원정보수정</a></li>
                <li @click="goPageByName('partner')" :class="{'on': $route.name === 'partner'}" v-if="userData">
                   <a v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'"><em class="linfo"></em>{{ $t('front.common.partner') }}</a>
                </li>
              </ul>
            </div>
         </li>
        </ul>
     </div>
  </transition-->
  <transition name="slide">
     <div class="sleftHeader">
        <ul>
           <!--li><a class="lcasino" @click="goPageByName('main')"></a></li>
           <li><a class="lhcasino" @click="goPageByName('main')"></a></li>
           <li><a class="lslot" @click="goPageByName('main')"></a></li-->
           <li><a class="lin" @click="goPageByName('charge')" :class="{'on': $route.name === 'charge'}"></a></li>
           <li><a class="lout" @click="goPageByName('exchange')" :class="{'on': $route.name === 'exchange'}"></a></li>
           <!--li><a class="lnotice" @click="goPageByName('notice')" :class="{'on': $route.name === 'noticeList'}"></a></li-->
           <li><a class="lcs" @click="goPageByName('csCenter')" :class="{'on': $route.name === 'qnaList'}"></a></li>
           <li><a class="lfaq" @click="goPageByName('faq')" :class="{'on': $route.name === 'faqList'}"></a></li>
           <li><a class="lbet" @click="goPageByName('bettingList')" :class="{'on': $route.name === 'bettingList'}"></a></li>
           <!--li><a class="lpoint" @click="goPageByName('point')" :class="{'on': $route.name === 'pointUse'}"></a></li>
           <li><a class="lmsg" @click="goPageByName('message')" :class="{'on': $route.name === 'messageList'}"></a></li-->
           <li><a class="linfo" @click="goPageByName('info')" :class="{'on': $route.name === 'info'}"></a></li>
           <li v-if="userData">
             <a class="lpart" :href="'/HZ8'" target="_blank" :class="{'on': $route.name === 'partner'}" v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'"></a>
           </li>
        </ul>
     </div>
  </transition>

  <header v-if="gameCount">
    <ul class="header">
      <li class="logowrap">
        <a @click="goPageByName('main')">
          <img src="@/assets/img/play_pokari.svg" />
        </a>
      </li>

      <li class="menuWrap">
        <div>
          <template v-if="!userData">
            <div class="loginWrap">
              <ui-button :className="'joinbtn headerbtn'" :text="$t('front.common.signup')" @click="onClickSignup"/>
              <ui-button :className="'loginbtn headerbtn'" :text="$t('front.gnb.login')" @click="onClickLogin"/>
            </div>
          </template>
          <template v-else>
            <ul class="coninpoint" v-if="userData">
              <li>
                 <i class="icon"><img src="@/assets/img/icon/icon_user.svg" /></i>
                 <span @click="goPageByName('messageList')">
                    <em>받은 쪽지</em>
                    {{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}
                 </span>
              </li>
              <li>
                 <span>
                    <em>{{$t('front.give.haveCash')}}</em>
                    {{thousand(userData.cashAmt ? userData.cashAmt.toString(): '0')}}
                 </span>
              </li>
              <li>
                 <button class="headerbtn_coinplus" @click="goPageByName('charge')">
                    <img src="@/assets/img/icon/icon_plusw.svg" />
                 </button>
              </li>
              <li>
                 <span>
                    <em>{{$t('front.give.havePoint')}}</em>
                    {{thousand(userData.pointAmt ? userData.pointAmt.toString(): '0')}}
                 </span>
              </li>
              <li>
                 <button class="headerbtn_coinplus" @click="goPageByName('point')">
                    <img src="@/assets/img/icon/icon_plusw.svg" />
                 </button>
              </li>
              <!--li><button class="exBtn" @click="onClickTM()">통합머니 전환</button></li>
              <li style="cursor: pointer" @click="goPageByName('point')"><img src="@/assets/img/icon_point.png" /><span class="bgnc">{{thousand(userData.pointAmt ? userData.pointAmt.toString() : '0')}}</span><button class="headerbtn_coinplus">+</button></li-->
              <li @click="signOut"><img src="@/assets/img/icon/icon_logout.svg" /></li>
            </ul>
          </template>
        </div>
      </li>

      <!--li class="loginWrap" @mouseleave="showMenuLang = false">
        <--ui-button :className="'joinbtn'" :text="'회원가입'" @click="onClickSignup" v-if="!userData"/->
        <div class="logindropdown" @mouseover="gnbMenuOpen('lang')">
          <button class="logindropbtn langbtn">
            <span class="langsp lang" v-if="$i18n.locale === 'ko'"><--{{ $t('front.gnb.selectLang') }}-><img src="@/assets/img/korea.png" /><span>KR</span></span>
            <span class="langsp lang" v-if="$i18n.locale === 'en'"><--{{ $t('front.gnb.selectLang') }}-><img src="@/assets/img/eng.png" /><span>EN</span></span>
            <span class="langsp lang" v-if="$i18n.locale === 'vn'"><--{{ $t('front.gnb.selectLang') }}-><img src="@/assets/img/vn.svg" /><span>VN</span></span>
          </button>
          <transition name="fade">
            <div class="logindropdown-content lang" v-if="showMenuLang">
              <a href="#" :class="{'on': $i18n.locale === 'ko'}" @click="changeLocale('ko')"><img src="@/assets/img/korea.png" /><span>KR</span></a>
              <a href="#" :class="{'on': $i18n.locale === 'en'}" @click="changeLocale('en')"><img src="@/assets/img/eng.png" /><span>EN</span></a>
              <a href="#" :class="{'on': $i18n.locale === 'vn'}" @click="changeLocale('vn')"><img src="@/assets/img/vn.svg" /><span>VN</span></a>
            </div>
          </transition>
        </div>
      </li-->
    </ul>
    <div class="naviwrap" >
       <ul class="navi">
         <li class="dropdown" @mouseover="gnbMenuOpen('all')">
            <a class="dropdown-toggle menu"><img src="@/assets/img/icon_menu.svg" /></a>
         </li>
         <li class="dropdown" @mouseover="gnbMenuOpen('best')">
           <a class="dropdown-toggle">인기게임</a>
         </li>
         <li class="dropdown" @mouseover="gnbMenuOpen('game')" v-if="gameCount['casino'] || gameCount['hc-casino']" >
           <a class="dropdown-toggle">{{ $t('front.gnb.casino') }}</a>
         </li>
         <li class="dropdown" @mouseover="gnbMenuOpen('slot')" v-if="gameCount['slot'] || gameCount['h-slot']" >
           <a class="dropdown-toggle">{{ $t('front.gnb.slot') }}</a>
         </li>
         <li class="dropdown" @mouseover="gnbMenuOpen('sport')" v-if="gameCount['sports']">
           <a class="dropdown-toggle">{{ $t('front.gnb.sport') }}</a>
         </li>
         <!--li class="dropdown">
           <a @click="onCasinoSelectGame('minigame', 33)">하이로우</a>
         </li>
         <li class="dropdown" @mouseover="gnbMenuOpen('ball')">
           <a class="dropdown-toggle">파워볼</a>
         </li-->
         <li class="dropdown" @mouseover="gnbMenuOpen('minigame')" v-if="gameCount['mini-game']">
           <a class="dropdown-toggle">{{ $t('front.gnb.minigame') }}</a>
         </li>
         <li class="dropdown" @mouseover="gnbMenuOpen('notice')">
           <a class="dropdown-toggle" @click="goPageByName('notice')">{{ $t('front.common.notice') }}</a>
         </li>
         <li class="dropdown" @mouseover="gnbMenuOpen('cs')">
           <a class="dropdown-toggle" @click="goPageByName('csCenter')">{{ $t('front.common.scscenter') }}</a>
         </li>
         <li class="dropdown" @mouseover="gnbMenuOpen('cash')">
           <a class="dropdown-toggle">{{ $t('front.common.depositWithdrawal') }}</a>

         </li>
         <li class="dropdown" @mouseover="gnbMenuOpen('mypage')" v-if="userData">
           <a class="dropdown-toggle" @click="goPageByName('mypage')">{{ $t('front.common.mypage') }}</a>
         </li>
         <li class="dropdown" @mouseover="gnbMenuOpen('partner')" v-if="userData">
           <a class="dropdown-toggle" :href="'/HZ8'" target="_blank" v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'">{{ $t('front.common.partner') }}</a>
         </li>
       </ul>
    </div>
    <transition name="fade">
     <div class="dropdown-menu" v-if="isAllOpen" @mouseleave="isAllOpen = false">
        <template v-if="gameCount">
          <ul class="navitop">
           <li>{{ $t('front.gnb.sport') }}</li>
          </ul>
          <ul class="navibottom">
           <li>
              <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.codeName">
               <a @click="onCasinoSelectGame(item.groupCode, item.code)" v-if="item.groupCodeNameEN.indexOf('H-') == -1">
                  <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                  <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
               </a>
              </template>
           </li>
          </ul>
          <ul class="navitop top2">
            <li>{{ $t('front.gnb.livecasino') }}</li>
          </ul>
          <ul class="navibottom">
            <li>
             <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.code" >
                <a @click="onCasinoSelectGame(item.groupCode, item.code)" v-if="item.groupCodeNameEN.indexOf('HC-') == -1">
                   <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                   <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
                </a>
             </template>
            </li>
          </ul>
          <ul class="navitop top2">
            <li>{{ $t('front.gnb.hotelcasino') }}</li>
          </ul>
          <ul class="navibottom">
            <li>
             <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.code" >
                <a @click="onCasinoSelectGame(item.groupCode, item.code)" v-if="item.groupCodeNameEN.indexOf('HC-') > -1">
                   <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                   <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
                </a>
             </template>
            </li>
          </ul>
          <ul class="navitop top2">
           <li>{{ $t('front.gnb.slotgame') }}</li>
          </ul>
          <ul class="navibottom">
           <li>
              <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.codeName">
               <a @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"  v-if="item.groupCodeNameEN.indexOf('H-') == -1">
                  <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                  <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
               </a>
              </template>
           </li>
          </ul>
          <ul class="navitop top2">
           <li>{{ $t('front.gnb.minigame') }}</li>
          </ul>
          <ul class="navibottom">
           <li>
              <template v-for="item in commonCodeByOrder['minigame']" v-bind:key="item.codeName">
               <a @click="onCasinoSelectGame(item.groupCode, item.code)" v-if="item.groupCodeNameEN.indexOf('H-') == -1">
                  <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                  <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
               </a>
              </template>
           </li>
          </ul>
       </template>
     </div>
   </transition>
   <transition name="slide-fade">
     <div class="dropdown-menu" v-if="isBestOpen" @mouseleave="isBestOpen = false">
      <ul class="navitop">
        <li>인기게임</li>
      </ul>
      <ul class="navibottom">
        <li>
         <a @click="onCasinoSelectGame('sport', 'splus')">
            <span class="img spl"></span>
            <span class="dropname">SPLUS 스포츠</span>
         </a>
        </li>
        <li>
         <a @click="onCasinoSelectGame(casino, 1)">
            <span class="img ev"></span>
            <span class="dropname">에볼루션</span>
         </a>
        </li>
        <li>
         <a @click="slotOpen('isSlot', 'slot', '프라그마틱플레이 슬롯', 200)">
            <span class="img prs"></span>
            <span class="dropname">프라그마틱 슬롯</span>
         </a>
        </li>
        <li>
         <a @click="onCasinoSelectGame('minigame', 33)">
            <span class="img hl"></span>
            <span class="dropname">하이로우</span>
         </a>
        </li>
        <li>
         <a @click="onCasinoSelectGame(casino, 10)">
            <span class="img prc"></span>
            <span class="dropname">프라그마틱 카지노</span>
         </a>
        </li>
        <li>
         <a @click="onCasinoSelectGame(casino, 13)">
            <span class="img bota"></span>
            <span class="dropname">보타</span>
         </a>
        </li>
        <li>
         <a @click="onCasinoSelectGame('minigame', 'niu')">
            <span class="img niu"></span>
            <span class="dropname">니우니우</span>
         </a>
        </li>
      </ul>
     </div>
  </transition>
    <transition name="slide-fade">
      <div class="dropdown-menu" v-if="isGameOpen" @mouseleave="isGameOpen = false">
        <template v-if="gameCount['casino']">
          <ul class="navitop">
            <li>{{ $t('front.gnb.livecasino') }}</li>
          </ul>
          <ul class="navibottom">
            <li>
             <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.code" >
                <a @click="onCasinoSelectGame(item.groupCode, item.code)" v-if="item.groupCodeNameEN.indexOf('HC-') == -1">
                   <span class="img" :style="getBackgroundImage(item)"></span>
                   <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                   <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
                </a>
             </template>
            </li>
          </ul>
        </template>

        <template v-if="gameCount['hc-casino']">
          <ul class="navitop top2">
            <li>{{ $t('front.gnb.hotelcasino') }}</li>
          </ul>
          <ul class="navibottom">
            <li>
             <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.code" >
                <a @click="onCasinoSelectGame(item.groupCode, item.code)" v-if="item.groupCodeNameEN.indexOf('HC-') > -1">
                   <span class="img" :style="getBackgroundImage(item)"></span>
                   <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                   <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
                </a>
             </template>
            </li>
          </ul>
        </template>
      </div>
   </transition>

   <transition name="slide-fade">
    <div class="dropdown-menu" v-if="isSportsOpen" @mouseleave="isSportsOpen = false">
      <ul class="navitop">
         <li>{{ $t('front.gnb.sport') }}</li>
      </ul>
      <ul class="navibottom">
         <li>
          <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.code" >
             <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                <span class="img" :style="getBackgroundImage(item)"></span>
                <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
             </a>
          </template>
         </li>
      </ul>
    </div>
   </transition>

   <transition name="slide-fade">
    <div class="dropdown-menu" v-if="isBallOpen" @mouseleave="isBallOpen = false">
      <ul class="navitop">
         <li>준비중입니다.</li>
      </ul>
      <!--ul class="navibottom">
         <li>
          <template v-for="item in commonCodeByOrder['ball']" v-bind:key="item.code" >
             <a @click="onCasinoSelectGame(item.gameType, item.code)">
                <span class="img" :style="getBackgroundImage(item)"></span>
                <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
             </a>
          </template>
         </li>
      </ul-->
    </div>
   </transition>

   <transition name="slide-fade">
    <div class="dropdown-menu" v-if="isSlotOpen" @mouseleave="isSlotOpen = false">
      <template v-if="gameCount['slot']">
         <ul class="navitop">
          <li>{{ $t('front.gnb.slotgame') }}</li>
         </ul>
         <ul class="navibottom sloth">
          <li>
             <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.codeName">
              <a @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"  v-if="item.groupCodeNameEN.indexOf('H-') == -1">
                 <span class="img" :style="getBackgroundImage(item)"></span>
                 <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                 <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
              </a>
             </template>
          </li>
         </ul>
      </template>

      <template v-if="gameCount['h-slot']">
         <ul class="navitop top2">
          <li>{{ $t('front.gnb.hotelslot') }}</li>
         </ul>
         <ul class="navibottom">
          <li>
             <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.codeName">
              <a @click="slotOpen('isSlot', 'slot', item.codeName, item.code)" v-if="item.groupCodeNameEN.indexOf('H-') > -1">
                 <span class="img" :style="getBackgroundImage(item)"></span>
                 <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                 <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
              </a>
             </template>
          </li>
         </ul>
      </template>
    </div>
   </transition>

   <transition name="slide-fade">
    <div class="dropdown-menu" v-if="isMiniOpen" @mouseleave="isMiniOpen = false">
      <ul class="navitop">
         <li>{{ $t('front.gnb.minigame') }}</li>
      </ul>
      <ul class="navibottom">
         <li>
          <template v-for="item in commonCodeByOrder['minigame']" v-bind:key="item.code" >
             <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                <span class="img" :style="getBackgroundImage(item)"></span>
                <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
             </a>
          </template>
         </li>
      </ul>
    </div>
   </transition>

   <transition name="fade">
     <div class="dropdown-menu" v-if="isCashOpen" @mouseleave="isCashOpen = false">
       <ul class="navibottom money">
         <li>
           <a @click="goPageByName('charge')"><span class="dropname">{{ $t('front.common.deposit') }}</span></a>
           <a @click="goPageByName('exchange')"><span class="dropname">{{ $t('front.common.withdrawal') }}</span></a>
         </li>
       </ul>
     </div>
   </transition>

   <div class="head_info">
     <div class="hinfo_wrap">
      <div class="topbanner">
         <div class="topbannerin">
             <div class="speakerIcon">공지</div> <!--교체-->
             <span class="">{{$t('front.header.msg')}}</span>
         </div>
      </div>
      <!-- <div class="minfo">
           <ul class="coninpoint" v-if="userData">
             <li><span>{{userData.memNick}}</span></li>
             <li><img src="@/assets/img/hcoin.svg" /><span>{{thousand(userData.cashAmt ? userData.cashAmt.toString(): '0')}}</span></li>
             <li style="cursor: pointer" @click="goPageByName('point')"><img src="@/assets/img/hpoint.svg" /><span>{{thousand(userData.pointAmt ? userData.pointAmt.toString() : '0')}}</span></li>
             <li class="mymsgs" @click="goPageByName('message')">
               <em></em>
               <span>{{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}</span>
             </li>
           </ul>

           <div class="loginWrap" @mouseleave="showMenuLang = false">
             <div class="logindropdown" @mouseover="gnbMenuOpen('lang')">
               <button class="logindropbtn langbtn">
                 <span class="langsp lang" v-if="$i18n.locale === 'ko'"><img src="@/assets/img/korea.svg" /><span>KR</span></span>
                 <span class="langsp lang" v-if="$i18n.locale === 'en'"><img src="@/assets/img/en.svg" /><span>EN</span></span>
                 <span class="langsp lang" v-if="$i18n.locale === 'vn'"><img src="@/assets/img/vn.svg" /><span>VN</span></span>
               </button>
               <transition name="fade">
                 <div class="logindropdown-content lang" v-if="showMenuLang">
                   <a href="#" :class="{'on': $i18n.locale === 'ko'}" @click="changeLocale('ko')"><img src="@/assets/img/korea.svg" /><span>KR</span></a>
                   <a href="#" :class="{'on': $i18n.locale === 'en'}" @click="changeLocale('en')"><img src="@/assets/img/en.svg" /><span>EN</span></a>
                   <a href="#" :class="{'on': $i18n.locale === 'vn'}" @click="changeLocale('vn')"><img src="@/assets/img/vn.svg" /><span>VN</span></a>
                 </div>
               </transition>
             </div>
           </div>
      </div> -->
     </div>
   </div>

  </header>

  <!--div v-if="userData" id="sideNav">
    <ul id="sideNavTop">
      <li class="side_user" v-if="userData" @click="goPageByName('mypage')">
        <span><span class="sideicon"></span></span>
        <em>{{userData.memNick}}</em>
      </li> <--회원이름->
      <li class="side_bet" @click="goPageByName('bettingList')">
        <span><span class="sideicon"></span></span>
        <em>베팅내역</em>
      </li> <--베팅내역->
      <li class="side_inout" @click="goPageByName('charge')">
        <span><span class="sideicon"></span></span>
        <em>입출금내역</em>
      </li> <--입출금내역->
      <li class="side_point" @click="goPageByName('point')">
        <span><span class="sideicon"></span></span>
        <em>포인트</em>
      </li> <--포인트->
      <li class="side_msg" @click="goPageByName('messageList')">
        <span><span class="sideicon"></span></span>
        <em>쪽지({{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}})</em>
      </li> <--쪽지->
      <li class="side_set" @click="goPageByName('info')">
        <span><span class="sideicon"></span></span>
        <em>정보수정</em>
      </li> <--정보수정->
      <li class="side_partner" @click="goPageByName('partner')" v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'">
        <span><span class="sideicon"></span></span>
        <em>파트너</em>
      </li> <--파트너->
    </ul>
    <div id="sideNavBottom">
      <div class="logindropdown" @mouseover="gnbMenuOpen('lang')">
          <button class="logindropbtn langbtn">
            <span class="langsp lang" v-if="$i18n.locale === 'ko'"><img src="@/assets/img/korea.png" /><span></span></span>
            <span class="langsp lang" v-if="$i18n.locale === 'en'"><img src="@/assets/img/eng.png" /><span></span></span>
            <span class="langsp lang" v-if="$i18n.locale === 'vn'"><img src="@/assets/img/vn.svg" /><span></span></span>
          </button>
          <transition name="fade">
            <div class="logindropdown-content lang" v-if="showMenuLang">
              <a href="#" :class="{'on': $i18n.locale === 'ko'}" @click="changeLocale('ko')"><img src="@/assets/img/korea.png" /><span></span></a>
              <a href="#" :class="{'on': $i18n.locale === 'en'}" @click="changeLocale('en')"><img src="@/assets/img/eng.png" /><span></span></a>
              <a href="#" :class="{'on': $i18n.locale === 'vn'}" @click="changeLocale('vn')"><img src="@/assets/img/vn.svg" /><span></span></a>
            </div>
          </transition>
        </div>
        <div class="logoutbtnWrap" v-if="userData">
          <ui-button :className="'logoutbtn'" @click="signOut"/>
        </div>
    </div>
  </div-->

</template>

<script>
import UiButton from '@/components/ui/UiButton'

import { mapState } from 'vuex'
import Join from '@/components/member/Join'
import JoinComplete from '@/components/member/JoinComplete'
import Signin from '@/components/member/Signin'
import TMmodal from '@/components/common/TotalMoney'
import { TRIPLE_GAME_LIST } from '@/libs/constants'
import NewMessage from '@/components/member/Message.vue'

export default {
  name: 'Header',
  components: {
    NewMessage,
    Signin,
    JoinComplete,
    Join,
    UiButton,
    TMmodal
  },
  data () {
    return {
      menuList: [],
      TMOpen: false,
      isLoginOpen: false,
      isSignupOpen: false,
      isSignupCompleteOpen: false,
      showMenu: false,
      showMenuMoney: false,
      showMenuLang: false,
      isAlertOpen: false,
      isAllOpen: false,
      isBestOpen: false,
      isGameOpen: false,
      isHgameOpen: false,
      isSlotOpen: false,
      isSportsOpen: false,
      isMiniOpen: false,
      isBallOpen: false,
      isCashOpen: false,
      tripleGameList: TRIPLE_GAME_LIST
    }
  },
  watch: {
    $route: {
      handler (current) {
        console.log('$route name', this.$route)
        this.gnbReset()
      }
    }
  },
  props: ['selectedMenu'],
  created () {
    this.setMenu()
    this.getHeadMsgList()
  },
  computed: {
    ...mapState([
      'userData',
      'coinAmt',
      'commonCodeByOrder',
      'gameCount',
      'unReadMessageCount'
    ]),
    isUnReadMessage () {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  methods: {
    slotOpen (event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
    },
    gnbReset () {
      this.isAllOpen = false
      this.isBestOpen = false
      this.isGameOpen = false
      this.isHgameOpen = false
      this.isSlotOpen = false
      this.isSportsOpen = false
      this.isMiniOpen = false
      this.isBallOpen = false
      this.isCashOpen = false
      this.showMenu = false
      this.showMenuLang = false
    },
    gnbMenuOpen (type) {
      this.gnbReset()
      if (type === 'game') {
        this.isGameOpen = true
      } else if (type === 'all') {
        this.isAllOpen = true
      } else if (type === 'best') {
        this.isBestOpen = true
      } else if (type === 'hgame') {
        this.isHgameOpen = true
      } else if (type === 'slot') {
        this.isSlotOpen = true
      } else if (type === 'cash') {
        this.isCashOpen = true
      } else if (type === 'mypage') {
        this.showMenu = true
      } else if (type === 'lang') {
        this.showMenuLang = true
      } else if (type === 'sport') {
        this.isSportsOpen = true
      } else if (type === 'minigame') {
        this.isMiniOpen = true
      } else if (type === 'ball') {
        this.isBallOpen = true
      }
    },
    getHeadMsgList () {
      // getHeadMsg({}).then(response => {
      //   console.log(response)
      // })
    },
    setMenu () {
      const list = this.$router.getRoutes()
      for (let i = 0, iLen = list.length; i < iLen; i++) {
        const item = list[i]
        if (item.meta && item.meta.gnb) {
          item.isSubOpen = false
          this.menuList.push(item)
        }
      }
    },
    onClickGame (code, name) {
      this.gnbReset()
      if (code) {
        // minigame

        if (code === 'mg') {
          this.$router.push({ name: name })
        } else {
          this.onSelectGame(code)
        }
      } else {
        this.onCheck('준비 중입니다.')
      }
    },
    onClickGnb (item) {
      const name = item.name
      const meta = item.meta
      if (meta.game) {
        this.onSelectGame(meta.gameCode)
      } else {
        this.$router.push({ name: name })
      }
    },
    async onClickTM () {
      const confirmMessage = '통합머니 전환을 진행하시겠습니까?'
      const confirmResult = await this.onConfirm(confirmMessage)
      if (confirmResult) {
        this.TMOpen = true
      }
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    onCloseTM () {
      this.TMOpen = false
    },
    onClickLogin () {
      this.isLoginOpen = true
      this.isSignupOpen = false
    },
    onClickSignup () {
      this.isLoginOpen = false
      this.isSignupOpen = true
    },
    onCloseSignin (value) {
      this.isLoginOpen = false
      if (value === 'join') {
        this.isSignupOpen = true
      }
      if (value === 'signin') {
        // location.reload()
      }
    },
    onCloseSignup (status) {
      this.isSignupOpen = false
      if (status) {
        this.isSignupCompleteOpen = true
      }
    }
  }
}
</script>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/common.css"></style>
